import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { Collapse } from "antd";
// import LogoFooter from "../../assets/images/belgiumimmo-footer.svg";
import LogoFooter from "../../assets/images/propy-footer.svg";
import ArrowIcon from "../../assets/images/arrow-gray.svg";
import line from "../../assets/images/line.svg";
import styled from "styled-components";
import { useLinkTranslation } from "../../custom-hooks/useLinkTranslation";
import Image from "next/image";
import moment from "moment-timezone";
const facebook = "https://www.facebook.com/BelgiumImmo-112375407620627";
const linkedin = "https://www.linkedin.com/company/80452911";
import { RootState } from "../../types/state";
import { useSelector } from "react-redux";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { config } from "../../config/siteConfigs";

const { Panel } = Collapse;

const LineStyling = styled.div`
	@media (min-width: 577px) {
		img {
			display: none;
		}
	}
	@media (max-width: 576px) {
		img {
			height: 2px;
			width: 100%;
		}
	}
`;

const FooterContainer = () => {
	const { t } = useTranslation("common");
	const { t: t2 } = useTranslation("header");
	const { RouterLink } = useLinkTranslation();

	const [areOfficeHours, setAreOfficeHours] = useState(true);

	const { auth, firstname, lastname, avatar } = useSelector(
		(state: RootState) => state.userInfo
	);
	const account_type = useSelector(
		(state: RootState) => state?.userInfo?.account_type
	);
	const isLoggedIn = auth;

	useEffect(() => {
		var EuTimeH = moment().tz("Europe/Berlin").hours();
		var EuTimeD = moment().tz("Europe/Berlin").weekday();

		if (EuTimeH >= 9 && EuTimeH < 19 && EuTimeD >= 1 && EuTimeD <= 5) {
			setAreOfficeHours(true);
		} else {
			setAreOfficeHours(false);
		}
	}, []);

	return (
		<div id="footer-container" className="footer">
			<div className="container">
				<div className="logo-container flex-column flex-md-row flex-lg-column align-items-center align-items-md-start">
					<div className="image-container">
						<Image layout="fill" src={LogoFooter} alt="LogoFooter" />
					</div>
					<div className="footer-description ml-0 ml-md-4 ml-lg-0">
						{t("footer.description")}
					</div>
				</div>
				<div
					className="two-container"
					style={{ ...(!isLoggedIn && { flex: 1 }) }}
				>
					{account_type !== "agent" && (
						<div className="one-container">
							<div className="options-container">
								<h5>{t("h6.navigation")} </h5>
								<div className="options">
									<div className="option">
										<RouterLink href="/for-sale">
											<a>
												{t2("a.buy")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div>
									<div className="option">
										<RouterLink href="/for-rent">
											<a>
												{t2("a.rent")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div>
									<div className="option">
										<RouterLink href="/estimate-home">
											<a>
												{t2("a.estimate")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div>
									{/* <div className="option">
										<RouterLink href="/agencies">
											<a>
												{t2("a.find-agencies")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div> */}
									<div className="option">
										<RouterLink href="/blogs">
											<a>
												{t2("nav-li.blogs")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div>
								</div>
							</div>
							<div className="collapse-options">
								<Collapse
									accordion
									expandIconPosition="end"
									bordered={false}
									ghost
								>
									<Panel key="1" header={<h5>{t("h6.navigation")}</h5>}>
										<div className="options">
											<div className="option">
												<RouterLink href="/for-sale">
													<a>
														{t2("a.buy")}
														<img src={ArrowIcon} alt="ArrowIcon" />
													</a>
												</RouterLink>
											</div>
											<div className="option">
												<RouterLink href="/for-rent">
													<a>
														{t2("a.rent")}
														<img src={ArrowIcon} alt="ArrowIcon" />
													</a>
												</RouterLink>
											</div>
											<div className="option">
												<RouterLink href="/estimate-home">
													<a>
														{t2("a.estimate")}
														<img src={ArrowIcon} alt="ArrowIcon" />
													</a>
												</RouterLink>
											</div>
											<div className="option">
												<RouterLink href="/agencies">
													<a>
														{t2("a.find-agencies")}
														<img src={ArrowIcon} alt="ArrowIcon" />
													</a>
												</RouterLink>
											</div>
											<div className="option">
												<RouterLink href="/blogs">
													<a>
														{t2("nav-li.blogs")}
														<img src={ArrowIcon} alt="ArrowIcon" />
													</a>
												</RouterLink>
											</div>
										</div>
									</Panel>
								</Collapse>
							</div>
							<LineStyling>
								<img width="100%" height="30px" src={line} alt="line" />
							</LineStyling>
						</div>
					)}
					{isLoggedIn && account_type !== "agent" && (
						<div className="one-container">
							<div className="options-container">
								<h5>{t("h6.resources")}</h5>
								<div className="options">
									{/* <div className="option">
										<RouterLink href="/dashboard">
											<a>
												Dashboard
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div> */}
									<div className="option">
										<RouterLink href="/properties">
											<a>
												{t2("nav-li.myEstimates")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div>
									<div className="option">
										<RouterLink href="/saved-searches">
											<a>
												{t2("nav-li.savedSearches")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div>
									<div className="option">
										<RouterLink href="/saved-property">
											<a>
												{t2("nav-li.savedProperties")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div>
									<div className="option">
										<RouterLink href="/settings">
											<a>
												{t("footer.settings")}
												<img src={ArrowIcon} alt="ArrowIcon" />
											</a>
										</RouterLink>
									</div>
								</div>
							</div>
							<div className="collapse-options">
								<Collapse
									accordion
									expandIconPosition="end"
									bordered={false}
									ghost
								>
									<Panel key="1" header={<h5>{t("h6.resources")}</h5>}>
										<div className="options">
											{/* <div className="option">
												<RouterLink href="/dashboard">
													<a>
														Dashboard
														<img src={ArrowIcon} alt="ArrowIcon" />
													</a>
												</RouterLink>
											</div> */}
											<div className="option">
												<RouterLink href="/properties">
													<a>
														{t("footer.myProperties")}
														<img src={ArrowIcon} alt="ArrowIcon" />
													</a>
												</RouterLink>
											</div>
											<div className="option">
												<RouterLink href="/settings">
													<a>
														{t("footer.settings")}
														<img src={ArrowIcon} alt="ArrowIcon" />
													</a>
												</RouterLink>
											</div>
										</div>
									</Panel>
								</Collapse>
							</div>
							<LineStyling>
								<img width="100%" height="30px" src={line} alt="line" />
							</LineStyling>
						</div>
					)}
				</div>
				<div className="two-container">
					<div className="one-container">
						<div className="get-in-touch">
							<h5>{t("btn.get-in-touch")}</h5>
							<h5 style={{ fontSize: 20, fontWeight: "bold", color: "white" }}>
								02 832 87 77
							</h5>
							<div className="lines">
								<div
									style={{
										width: 8,
										height: 8,
										borderRadius: "50%",
										background: areOfficeHours ? "#178D84" : "red",
										marginRight: 10,
									}}
								/>
								<li className="text-muted list-unstyled">
									{areOfficeHours ? t("li.lines-open") : t("li.lines-close")}
								</li>
							</div>
						</div>
						<LineStyling>
							<img width="100%" height="30px" src={line} alt="line" />
						</LineStyling>
					</div>
					<div className="one-container">
						<div className="social-container d-flex align-items-center align-items-md-start w-100 justify-content-between flex-row flex-md-column">
							<h5 className="pt-2 pt-md-0">{t("h6.social")} </h5>
							<div className="d-flex align-items-center">
								<a className="mr-2" href={facebook} target="blank">
									<div
										className="d-flex flex-row align-items-center justify-content-center"
										style={{
											width: 40,
											height: 40,
											borderRadius: 20,
											background: "#243E80",
										}}
									>
										<FaFacebookF style={{ color: "white" }} />
									</div>
								</a>

								<a href={linkedin} target="blank">
									<div
										className="d-flex flex-row align-items-center justify-content-center"
										style={{
											width: 40,
											height: 40,
											borderRadius: 20,
											background: "#243E80",
										}}
									>
										<FaLinkedinIn style={{ color: "white" }} />
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="all-rights-container">
				<div className="copyright">
					{t("footer.copyright")} © Propy {new Date().getFullYear()}
				</div>
				<div>
					<RouterLink href={"/terms-and-condition"}>
						{t("label.terms")}
					</RouterLink>
					{/* {'  |  '} <RouterLink href={''}>Cookies Policy</RouterLink> */}
					{"  |  "}{" "}
					<RouterLink href={"/privacy-policy"}>{t("label.privacy")}</RouterLink>
					{"  |  "} <RouterLink href={"/gdpr"}>{t("label.gdpr")}</RouterLink>
					{"  |  "}
					<a
						onClick={
							config.appEnv === "production"
								? /* @ts-ignore */
								  () => UC_UI.showSecondLayer()
								: () => {}
						}
					>
						{t("label.cookie-settings")}
					</a>
				</div>
			</div>
		</div>
	);
};

export default FooterContainer;
